@import "../../../assets/scss/_variables";
.dashboard__wrapper {
  background: $white-color;
  padding-top: 20px;
  .search__filter {
    form {
      width: 100%;
      .cross__icon {
        background: $transparent-color;
        border-left: 0;
        border-radius: 10px;
      }

      .input-group {
        width: 100%;
        outline: none;
        &:hover .tooltiptext {
          visibility: visible;
          z-index: 100;
          .search__Input {
            &::placeholder {
              font-family: $poppins-regular;
              font-weight: 400;
              font-size: 14px;
              line-height: 21px;
              color: $search-placeholder-color;
            }
          }
        }
        .tooltiptext {
          visibility: hidden;
          width: 100%;
          background-color: $light-gray-color;
          color: #fff;
          border-radius: 10px !important;
          padding: 10px 20px;
          font-family: $poppins-regular;
          font-weight: 400;
          font-size: 14px;
          line-height: 21px;
          /* Position the tooltip */
          position: absolute;
          top: 40px;
          z-index: 1;
          opacity: 0.9;
        }
        .search__icon {
          background: $transparent-color;
          border-right: 0;
          border-radius: 10px;
        }
        height: 36px;
        .search__Input {
          border: 1px solid $input-feild;
          width: 100%;
          // min-width: 378px;
          height: 100%;
          max-height: 36px;
          padding: 9px 4px 9px 40px !important;
          border-radius: 10px !important;
          background: transparent
            url("../../../assets/images/icons/icon-search.svg") no-repeat 12px;
          &:focus {
            border: 1px solid $blue-color;
            box-shadow: none;
          }
          &::-webkit-search-cancel-button {
            position: relative;
            right: 0px;
            -webkit-appearance: none;
            height: 20px;
            width: 20px;
            border-radius: 10px;
            background-image: url(../../../assets/images/icons/icon-cross.svg);
            background-repeat: no-repeat;
            background-position: center center;
            background-size: 100% 50%;
          }
        }
      }
    }
    .activebtn {
      border: 1px solid $blue-color !important;
      &:focus {
        border: 1px solid $blue-color;
      }
    }
    .filter__btn {
      font-size: 14px !important;
      font-family: $poppins-regular;
      font-weight: 400;
      color: $gray-color;
      border: 1px solid $gray-color;
      background: $transparent-color;
      height: 36px;
      border-radius: 10px;
      display: flex;
      align-items: center;
      &:active {
        color: $gray-color;
        border: 1px solid $gray-color;
        background: $transparent-color;
      }
      &:focus {
        box-shadow: none;
      }

      &:focus-visible {
        outline: none;
        border: 1px solid $gray-color;
      }
    }
  }
  .show-filter {
    display: flex;
    align-items: center;
    height: 36px;
    .current-filter {
      font-family: $poppins-regular;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      color: $gray-color;
    }
    .filter-parent-pro {
      background: #eeee;
      padding: 6px;
      border-radius: 6px;
    }
    .filter-processing {
      background: $filter-bg;
      padding: 2px 8px 2px 8px !important;
      border-radius: 5px;
      text-transform: capitalize;
      .filter-name {
        font-family: $poppins-regular;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: $page-title;
        text-transform: capitalize;
      }
      .cross-btn {
        cursor: pointer;
      }
      &:hover {
        font-weight: 400;
      }
    }
  }
  .filter-btn {
    background: $filter-bg;
    padding: 2px 8px;
    border-radius: 5px;
    font-family: $poppins-regular;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: $page-title;
    text-transform: capitalize;
    .cross-img {
      right: 10px;
      vertical-align: middle;
      top: 5px;
    }
    &:focus {
      box-shadow: none;
    }
  }
  // tooltip styling
  #popover-contained {
    width: 100%;
    max-width: 320px;
    z-index: 9999;
    border-radius: 8px;
    background-color: $white-color;
    filter: drop-shadow(0px 0px 10px rgba(90, 90, 90, 0.25));
    position: absolute;
    top: 50px;
    margin-left: 25px;
    &::after {
      content: url(../../../assets/images/popover-icon.svg);
      position: absolute;
      top: -18px;
      left: 10%;
      height: 20px;
      width: 20px;
    }
    border: none;
    .dropdown__body {
      height: 100%;
      .popover__footer {
        .clear__filter {
          font-family: $poppins-regular;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 21px;
          background: $transparent-color;
          color: $blue-color;
          border: none;
        }
        .apply__filter {
          font-family: $poppins-regular;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 21px;
          background: $blue-color;
          color: $white-color;
          outline: none;
          border: none;
          padding: 8px 16px;
          border-radius: 10px;
          display: flex;
          align-items: center;
          height: 36px;

          &:focus {
            box-shadow: none;
          }
        }
      }
    }

    .filter__title {
      border-radius: 8px;
      background: $white-color;
      font-family: $poppins-medium;
      font-weight: 500;
      font-size: 20px;
      line-height: 30px;
      color: $page-title;
      padding: 16px;
    }
    .accordion-body {
      background: $apply-filter-bg;
      font-family: $poppins-regular;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: $page-title;
      .form-check-label {
        font-family: $poppins-regular;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        color: $aborted-text-color;
        text-transform: capitalize;
        &:hover {
          font-weight: 600;
          cursor: pointer;
        }
      }
      .search_input {
        position: relative;
        display: inline-flex;
        align-items: center;
        width: 100%;

        &:focus {
          border: 1px solid $blue-color;
        }
        #input-icon {
          position: absolute;
          display: block;
          right: 3px;
          height: 15px;
          border-radius: 50%;
          color: #fff;
          background-color: $transparent-color;
          font: 13px monospace;
          text-align: center;
          line-height: 1em;
          cursor: pointer;
          &:after {
            content: url(../../../assets/images/icons/icon-cross.svg);
            background-position: center center;
            background-size: 100%;
          }
        }
        #submit-icon {
          position: absolute;
          display: block;
          right: 10px;
          bottom: 12px;
          height: 15px;
          transform: rotate(180deg);
          border-radius: 50%;
          color: #fff;
          background-color: $transparent-color;
          font: 13px monospace;
          text-align: center;
          line-height: 1em;
          cursor: pointer;
          &:after {
            content: url(../../../assets/images/icons/icon-arrow.svg);
            background-position: center center;
            background-size: 100%;
          }
        }
        input {
          padding-right: 18px;
          box-sizing: border-box;
          width: 100%;
          border: 1px solid $input-feild;
          height: 36px;
          padding: 10px 8px;
          margin-right: 50px;
          border-radius: 10px !important;
          outline: none;
          &:focus {
            border: 1px solid $blue-color;
            outline: none;
          }
        }
      }
      .form-radio {
        .form-check-input {
          background: $transparent-color;
          border: 1px solid $aborted-text-color;
          &:checked {
            border: 4px solid $blue-color !important;
            background-image: unset !important;
            background: unset !important;
          }
          &:focus {
            box-shadow: none;
          }
        }
      }
      .form-check-input:checked + .form-check-label {
        &:hover {
          font-weight: 600;
          color: $dark-gray-color;
          cursor: pointer;
        }
      }
      .form-check {
        .form-check-input {
          border: 1px solid $aborted-text-color;
          background: none;
          &:checked {
            background-color: $blue-color;
            background-image: url(../../../assets/images/icons/icon-check.svg);
            border: 2px solid $blue-color;
            background-size: 100%;
            background-repeat: no-repeat;
            background-position: center;
          }
          &:focus {
            box-shadow: none;
          }
        }
      }

      // #input-icon {
      //   &::after {
      //     content: url(../../../assets/images/icons/icon-cross.svg);
      //     background-position: center center;
      //     background-size: 100%;
      //   }
      // }
      .form-control {
        &:focus {
          border: 1px solid $blue-color;
          box-shadow: none !important;
          border-right: none;
          & + button {
            border: 1px solid $blue-color;
          }
        }
      }
      .input-group {
        input {
          border-right: none;
        }
      }
      // #input-icon {
      //   background: $white-color;
      //   border: 1px solid $input-feild;
      //   border-radius: 0px 5px 5px 0px;
      // }
    }
    .accordion-button {
      font-family: $poppins-regular;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: $gray-color;
      padding: 10px 15px;
      &.active {
        color: $page-title;
      }
      &:hover {
        font-weight: 600;
      }
      &::after {
        background-image: url(../../../assets/images/icons/icon-arrow.svg);
        background-size: 100%;
        width: 8px;
        transform: rotate(-90deg);
        background-repeat: no-repeat;
      }
      &:not(.collapsed) {
        color: $page-title;
        background-color: $transparent-color;
        font-family: $poppins-regular;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        &:after {
          background-image: url(../../../assets/images/icons/icon-active-arrow.svg);
          transform: rotate(180deg);
          background-size: 100%;
          width: 13px;
        }
        &:hover {
          font-weight: 700;
          color: $page-title;
        }
      }
    }
    .accordion-header {
      button {
        &:focus {
          box-shadow: none;
        }
      }
    }
  }

  .filterBackground{
    position: fixed;
    height: 100vh;
    width: 100vw;
    top: 0px;
    left: 0px;
    background: transparent;
    z-index: 9999;
  }

  .button__section {
    button {
      background: $primary-color;
      border: none;
      outline: none;
      border-radius: 8px;
      font-family: $workSans-bold;
      font-weight: 700;
      font-size: 16px;
      line-height: 19px;
      color: $white-color;
      width: 100%;
      padding: 16px;
      &:focus {
        box-shadow: none;
      }
    }
  }

}
//monish css styling//
.text-area-css {
  &:focus {
    box-shadow: none;
    border: 1px solid $blue-color;
  }
}
.priority-header {
  display: flex;
  justify-content: center;
  border: none;
  flex-wrap: wrap;
  flex-direction: row;

  .p-title {
    font-family: $poppins-medium;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
  }
  span {
    font-family: $poppins-regular;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: $page-title;
  }
}
.priority-footer {
  display: flex;
  justify-content: space-between;
  border: none;
}

.radio__css {
  .radio-css {
    display: flex;
    flex-direction: column;
    align-items: center;
    span {
      font-family: $poppins-regular;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      color: $gray-color;
    }
  }
  input.form-check-input {
    width: 18px;
    height: 18px;
    &:focus {
      box-shadow: none;
    }
  }
}
.btn-cancel {
  text-decoration: none;
  color: $blue-color;
  font-family: $poppins-regular;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  &:focus {
    box-shadow: none;
  }
  &:hover {
    text-decoration: none;
  }
}
.btn-change {
  background: $blue-color;
  border-radius: 10px;
  border: none;
  font-family: $poppins-regular;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  &:focus {
    box-shadow: none;
  }
  &:hover {
    text-decoration: none;
  }
}
.main-warning-body {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  .warning-change {
    .w-parent {
      font-family: $poppins-medium;
      font-weight: 500;
      font-size: 20px;
      line-height: 30px;
      color: $page-title;
    }
    .w-child {
      font-family: $poppins-regular;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      color: $page-title;
    }
  }
}

@media (max-width: 992px) {
  .dashboard__wrapper #popover-contained {
    margin-left: 0;
    right: 20px;
    z-index: 9999 !important;
    &::after {
      right: 10px;
      left: unset;
    }
  }
}
@media (min-width: 576px) {
  .container {
    max-width: 100%;
  }
}
.spinner {
  display: flex;
  justify-content: center;
  color: #5b98f0;
}

.jobs-table {
  tbody {
    tr {
      border-bottom: $table-border-color 1px solid;
      &:hover {
        background-color: $table-hover !important;
        .table__body__white-column {
          background-color: $table-hover !important;
        }
      }
    }
    td {
      font-family: $poppins-regular;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      color: $page-title;
      vertical-align: middle;
      max-width: 200px;
      min-width: 100px;
      word-wrap:break-word;
      padding: 0.5rem;
      a {
        color: $blue-color;
        font-family: $poppins-regular;
        text-decoration: none;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        min-width: 85px;
      }
      .edit {
        background: $transparent-color;
        color: $page-title;
        font-family: $poppins-regular;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        border: 1px solid $blue-color;
        border-radius: 10px;
        display: flex;

        .notesValue{
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          width: 9ch;
          margin: 0px;
        }
      }
    }
    .Processed {
      .status-badge {
        background: $processed-color;
        color: $processed-text-color;
        border-radius: 15px;
        height: 24px;

        max-width: 89px;
        &:hover {
          font-weight: 700;
          cursor: pointer;
        }
      }
    }
    .Processing {
      .status-badge {
        background: $processing-color;
        color: $processing-text-color;
        border-radius: 15px;
        height: 24px;

        display: flex;
        align-items: center;
        max-width: 92px;
        &:hover {
          font-weight: 700;
          cursor: pointer;
        }
      }
    }
    .status-badge {
      padding: 1.5px 8px;
    }
    .Failed {
      .status-badge {
        background: $failed-color;
        color: $failed-text-color;
        border-radius: 15px;
        height: 24px;

        display: flex;
        align-items: center;
        max-width: 58px;
        &:hover {
          font-weight: 700;
          cursor: pointer;
        }
      }
    }
    .Aborted {
      .status-badge {
        background: $aborted-color;
        color: $aborted-text-color;
        border-radius: 15px;
        height: 24px;

        display: flex;
        align-items: center;
        max-width: 73px;
        &:hover {
          font-weight: 700;
          cursor: pointer;
        }
      }
    }
    .Initiated {
      .status-badge {
        background: $initiated-color;
        color: $initiated-text-color;
        border-radius: 15px;
        height: 24px;

        display: flex;
        align-items: center;
        max-width: 74px;
        &:hover {
          font-weight: 700;
          cursor: pointer;
        }
      }
    }
  }
}


.table__head {
  background: $table-headBg;
  height: 53px;
  min-height: 53px;
  max-height: 53px;
  tr {
    th:nth-child(1) {
      text-align: center;
    }
  }
  th {
    padding: 0.5rem;
  }
}

.table__head__column {
  background: $table-headBg;
  font-family: $poppins-medium;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: $table-heading;
  min-width: 200px;
  top: 0;
  vertical-align: middle;
  z-index: 1;
  border-bottom: 2px solid #dee2e6;
}

.table__body__badge {
  width: 20px;
  height: 20px;
  background: $blue-color;
  color: $white-color;
  border-radius: 50%;
  vertical-align: middle;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  div {
    margin-top: 4px;
  }
  &:hover::after {
    content: "Change Priority";
    background: $light-gray-color;
    opacity: 0.8;
    border-radius: 8px;
    padding: 8px 12px;
    position: absolute;
    top: -15px;
    left: 5px;
    font-family: $poppins-regular;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: $white-color;
  }
}

.table__body__stuck-column {
  box-shadow: inset -1px 0px 0 $table-border-color !important;
}

.table__body__white-column {
  background-color: $white-color !important;
}

.table__body__sticky {
  position: sticky;
  left: 0;
  min-width: 250px;
}

.filter-bar {
  padding: 10px 0;
}
