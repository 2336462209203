@import "./assets/scss/_variables";

/* width */
.table-responsive::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  // display: none;
}

/* Track */
.table-responsive::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px $separator-color;
  border-radius: 10px;
  width: 18px;
  // display: none;
}

/* Handle */
.table-responsive::-webkit-scrollbar-thumb {
  background: $input-feild;
  border-radius: 10px;
  // display: none;  
}

/* Handle on hover */
.table-responsive::-webkit-scrollbar-thumb:hover {
  background: $input-feild;
}
/* width */
.datatable__Listing::-webkit-scrollbar {
  width: 5px;
  height: 8px;
}

/* Track */
.datatable__Listing::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px $separator-color;
}

/* Handle */
.datatable__Listing::-webkit-scrollbar-thumb {
  background: $input-feild;
  border-radius: 10px;
}

/* Handle on hover */
.datatable__Listing::-webkit-scrollbar-thumb:hover {
  background: $input-feild;
}

.btn-primary:not(:disabled):not(.disabled):active:focus {
  box-shadow: none;
}
button:disabled {
  opacity: 0.5;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
#comments-popover {
  width: 100%;
  max-width: 320px;
  border-radius: 8px;
  border: none;
  box-shadow: 0px 0px 8px 0px #5a5a5a40;

  img {
    cursor: pointer;
  }
  .popover__footer {
    .clear__filter {
      font-family: $poppins-regular;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      background: $transparent-color;
      color: $blue-color;
      border: none;
    }
    .apply__filter {
      font-family: $poppins-regular;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      background: $blue-color;
      color: $white-color;
      outline: none;
      border: none;
      padding: 8px 16px;
      width: 100%;
      max-width: 112px;
      border-radius: 10px;
      &:focus {
        box-shadow: none;
      }
    }
  }
  .filter__title {
    background: $white-color;
    font-family: $poppins-medium;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    color: $page-title;
  }
}
.auth-screensModals {
  .modal-body {
    height: 100%;
    min-height: 100vh;
  }
}

.sticky-col {
  position: sticky !important;
  left: 0;
}

.stuck-col__head {
  top: 0 !important;
  z-index: 100 !important;
}

.stuck-col__white-bg {
  background-color: $white-color !important;
}

.stuck-col__gray-border {
  box-shadow: inset -1px 0px 0 $table-border-color !important;
}

.trhover {
  tr {
    &:hover {
      .stuck-col__white-bg {
        background-color: $table-hover !important;
      }
    }
  }
}